<template>
  <v-container id="login" fluid tag="section" style="padding-bottom: 150px">
    <section>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="text-h3 font-weight-light">Login</div>
            </template>

            <validation-observer v-slot="{ invalid }">
              <v-form @submit.prevent="login">
                <v-container class="py-0">
                  <v-row>
                    <v-col cols="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <v-text-field
                          v-model="email"
                          type="email"
                          label="Email Address"
                        />
                        <span class="warning--text">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Password"
                        rules="required"
                      >
                        <v-text-field
                          v-model="password"
                          type="password"
                          label="Password"
                        />
                        <span class="warning--text">{{ errors[0] }}</span>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" md="3">
                      <v-btn
                        color="primary"
                        class="ml-0"
                        :disabled="invalid"
                        type="submit"
                      >
                        <v-icon class="mr-2" dark> mdi-send </v-icon>
                        Login
                      </v-btn>
                    </v-col>
                    <v-col cols="6" md="6">
                    </v-col>
                    <v-col cols="3" md="3">
                      <router-link to="/pages/forgot">
                        Forgot my password
                      </router-link>            
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </validation-observer>
          </base-material-card>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    name: null,
    password: null,
    email: null,
  }),
  methods: {
    login: function (e) {
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then((userCredentials) => {
          if(userCredentials) {
            this.$router.push({ name: "Property Search" });
          }
        });
    },
  },
};
</script>
